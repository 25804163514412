.steps-content {
  min-height: 80vh;
  margin-top: 16px;
  padding-top: 40px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}
