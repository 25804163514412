/* remove default margins and apply antialiasing */
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* apply default font-family to respective elements */
html,
body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol,
button,
input,
textarea {
  line-height: 1;
  margin: 0;
  padding: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

/* apply code-friendly font specifically on code blocks */
/* this will later be replaced by the monospaced font in TPDS */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* show correct blue background on the antd Button component */
.ant-btn-primary {
  background-color: #1890ff !important;
}

/* show correct blue hover shade on the antd Button component */
.ant-btn-primary:hover {
  background-color: #117de2 !important;
}

/* show correct blue background on the Switch component */
.ant-switch {
  background-color: #c2c2c2 !important;
}
.ant-switch-checked {
  background-color: #1890ff !important;
}

/* Ensure table rows remain white when hovering */
.ant-table-cell-row-hover {
  background: white !important;
}

/* Remove the vertical borders between the table header elements */
.ant-table-thead .ant-table-cell:before {
  display: none !important;
}

.ant-pagination-item > a {
  padding-top: 8px !important;
}
.ant-pagination-item-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.active-border-animation {
  border-width: 2px;
  border-style: solid;
  animation-name: pulse-border-active;
  animation-timing-function: linear;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes pulse-border-active {
  0% {
    border-color: transparent;
  }
  /* 20% {
    border-color: var(--border-blue);
  }
  40% {
    border-color: var(--border-blue);
  } */
  60% {
    border-color: var(--blue);
  }
  /* 80% {
    border-color: var(--border-blue);
  } */
  100% {
    border-color: transparent;
  }
}
