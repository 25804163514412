@import "~antd/dist/antd.css";
@import "../node_modules/antd-country-phone-input/dist/index.css";

.App {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.light {
  .ant-slider-rail {
    background-color: #ccc !important;
  }
}

.dark {
  .ant-slider-rail {
    background-color: #555;
  }
}

.logo {
  height: 24px;
}

.flex-container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.row {
  width: auto;
  border: 1px solid blue;
}

.flex-item {
  background-color: tomato;
  padding: 5px;
  width: 20px;
  height: 20px;
  margin: 10px;
  line-height: 20px;
  color: white;
  font-weight: bold;
  font-size: 2em;
  text-align: center;
}

.phone-case {
  border: none;
  -ms-zoom: 0.5;
  -moz-transform: scale(0.5);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.5);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}

/*Antd Style overrides*/
.ant-steps-item-icon .ant-steps-icon {
  top: -1.5px;
  left: -0.5px;
}

/*This fixes hover on antd upload image cards*/
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  left: 0px;
}
.ant-upload-list-item-list-type-picture-card {
}
.ant-upload-list-item,
.ant-upload-list-picture-card-container {
  background: var(--border-button-hover) !important;
  padding: 3px !important;
  border: none !important;
  border-radius: 6px !important;
}

/*Color Picker Override Start*/

/*Override react-linear-gradient-picker style for selected gradient tip*/
div.cs.active {
  border: 2.4px solid #1990ff;
  box-sizing: content-box;
}

.csh {
  /*margin-bottom: 4px;*/
}

/* End */

input[type="text"].ant-input,
textarea {
  display: flex !important;
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 6px;
  outline: none !important;
  box-shadow: none !important;
  font-weight: normal;
  background-color: #f4f4f4;
  border-color: transparent;
  border-width: 2px;
  /*${tw`text-primary!`};*/
  /*${tw`transition! duration-100!`};*/
  /*${tw`border-2! focus:border-blue!`};*/
}
textarea:focus {
  border: 2px solid #1990ff;
  outline: none !important;
}
.ant-select:focus {
  border: 2px solid #1990ff;
  outline: none !important;
}
.ant-select {
  display: flex;
  width: 100% !important;
  /*${tw`transition duration-100`};*/
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 4px;
  font-weight: normal;
  border-color: transparent;
  border-width: 2px;
  background-color: #f4f4f4;
  /*${tw`focus:outline-none`};*/
  /*${tw`text-primary!`};*/
  /*${tw`border-2 focus:border-blue`};*/
}
.ant-select-selection-search {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  width: auto !important;
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.ant-picker-range .ant-picker-active-bar {
  background: var(--blue) !important;
}
.ant-picker-cell-inner {
  border-radius: 3px !important;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: var(--blue) !important;
}
.ant-picker-cell-in-range:before {
  background: var(--blue) !important;
  opacity: 0.1;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-range-start-single
  )::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: var(--blue) !important;
  opacity: 0.1;
}
.ant-picker {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  border: none !important;
}
.light .ant-picker {
  background: var(--grey-light-200) !important;
}
.dark .ant-picker {
  background: var(--grey-dark-300) !important;
}
.ant-picker-panel-container {
  border: 1px solid var(--border-window) !important;
  border-radius: 3px !important;
}
.light .ant-picker-panel-container {
  background: var(--grey-light-200) !important;
}
.dark .ant-picker-panel-container {
  background: var(--grey-dark-300) !important;
}
.ant-picker-panel-container * {
  border-color: var(--border-window) !important;
}
.ant-picker-panel-container .ant-picker-header * {
  color: var(--text-secondary) !important;
}
.ant-picker-panel-container .ant-picker-body thead * {
  color: var(--text-tertiary) !important;
}
.ant-picker-panel-container .ant-picker-body tbody * {
  color: var(--text-primary) !important;
}
.light .ant-picker-cell:hover .ant-picker-cell-inner {
  background: var(--blue-50) !important;
}
.dark .ant-picker-cell:hover .ant-picker-cell-inner {
  background: var(--blue-700) !important;
}
.ant-picker-cell-in-view:hover .ant-picker-cell-inner {
  background: var(--blue) !important;
}
.light .ant-picker-cell-in-range .ant-picker-cell-inner {
  color: var(--blue) !important;
}
.dark .ant-picker-cell-in-range .ant-picker-cell-inner {
  color: var(--blue-200) !important;
}
.light .ant-picker-cell-range-start .ant-picker-cell-inner {
  color: white !important;
}
.light .ant-picker-cell-range-end .ant-picker-cell-inner {
  color: white !important;
}
.dark .ant-picker-cell-range-start .ant-picker-cell-inner {
  color: white !important;
}
.dark .ant-picker-cell-range-end .ant-picker-cell-inner {
  color: white !important;
}
.ant-picker input {
  color: var(--text-primary) !important;
}
.ant-picker svg {
  color: var(--text-input) !important;
}
.ant-picker-range input {
  font-family: Inter, sans-serif;
}

.ant-picker-range {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  border-width: 2px;
  border-color: transparent;
  background-color: var(--bg-window);
}

.light
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.light
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: var(--blue-200) !important;
}
.dark
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start
  .ant-picker-cell-inner::after,
.dark
  .ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  background: var(--blue-800) !important;
}

.light .ant-picker-range-arrow::before {
  background: var(--grey-light-200) !important;
}
.dark .ant-picker-range-arrow::before {
  background: var(--grey-dark-300) !important;
}
